import React, { useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import useWebSocket from 'react-use-websocket';

const WebSocketComponent = () => {
  const toast = useToast();
  const { lastMessage } = useWebSocket('wss://alert.arcisai.io:5080');

  const formatEventType = (eventType) => {
    switch (eventType) {
      case 'HD':
        return 'Human Detection';
      case 'FD':
        return 'Face Detection';
      default:
        return eventType; // Return the original value if no match
    }
  };

  useEffect(() => {
    if (lastMessage !== null) {
      try {
        const message = JSON.parse(lastMessage.data);

        // Extract and format data from the message
        const deviceSN = message.deviceSN || 'N/A';
        const timeStamp = message.timeStamp || 'N/A';
        const eventType = formatEventType(message.eventType || 'N/A');

        toast({
          title: 'AI Alert',
          description: (
            <div>
              <p><strong>Device SN:</strong> {deviceSN}</p>
              <p><strong>Timestamp:</strong> {timeStamp}</p>
              <p><strong>Event Type:</strong> {eventType}</p>
            </div>
          ),
          status: 'info',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error) {
        console.error('Failed to parse WebSocket message:', error);
      }
    }
  }, [lastMessage, toast]);

  return null; // Return null to render nothing
};

export default WebSocketComponent;
