import React, { useState, useRef, useEffect } from 'react';
import {
  Flex,
  Box,
  Text,
  FormControl,
  FormLabel,
  Checkbox,
  Button,
  Input
} from '@chakra-ui/react';
import axios from 'axios';


const MotionDetection = ({deviceId}) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [error, setError] = useState(null);

  const [sensitivityLevel, setSensitivityLevel] = useState('');
  const [isAlarmSoundEnabled, setIsAlarmSoundEnabled] = useState(false);
  const [isWhiteLightEnabled, setIsWhiteLightEnabled] = useState(false);

 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/getmotion',{
          params: {
            deviceId: deviceId
          }});
          setIsEnabled(response.data.enabled);
          setSensitivityLevel(response.data.detectionGrid.sensitivityLevel);
          
      } catch (error) {
        setError(error);
      }
      try {
        const response = await axios.get('https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/motion-alarm',{
          params: {
            deviceId: deviceId
          }});
        
          setIsAlarmSoundEnabled(response.data.AlarmSound);
          setIsWhiteLightEnabled(response.data.AlarmWhiteLight);
          
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, []); 

  const updateMotion = async () => {
    const parsedSensitivity = parseInt(sensitivityLevel, 10);
  
    // Validate sensitivity level
    if (isNaN(parsedSensitivity) || parsedSensitivity < 1 || parsedSensitivity > 100) {
      setError(new Error('Sensitivity level must be between 1 and 100.'));
      return;
    }
  
    const settings = {
      enabled: isEnabled,
      sensitivityLevel: parsedSensitivity,
    };
  
    try {
      const responseSettings = await axios.put('https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/motionDetection-settings', settings, {
        params: {
          deviceId: deviceId
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Motion Detection Settings Response:', responseSettings.data);
  
      const alarm = {
        AlarmSound: isAlarmSoundEnabled,
        AlarmWhiteLight: isWhiteLightEnabled,
      };
  
      const responseAlarm = await axios.put('https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/set-motion-alarm', alarm, {
        params: {
          deviceId: deviceId
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Motion Alarm Settings Response:', responseAlarm.data);
  
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      setError(error);
    }
  };
  
  return (
    <Flex direction="column" padding="10px" gap={4}>
    <Box>
      <Text fontWeight="bold" fontSize="lg" color="blue.500">
        Motion Detection
      </Text>
    </Box>
    <Flex>
      <FormControl display="flex" alignItems="center">
        <FormLabel marginBottom="0" mr={4}>
          Enabled
        </FormLabel>
        <Checkbox isChecked={isEnabled} onChange={(e) => setIsEnabled(e.target.checked)} />
      </FormControl>
    </Flex>
    <Flex direction="row" align="center" gap={4}>
      <FormControl display="flex" alignItems="center">
        <FormLabel marginBottom="0">Sensitivity Level</FormLabel>
        <Input
          value={sensitivityLevel}
          onChange={(e) => setSensitivityLevel(e.target.value)}
          width="auto"
        />
        <Text>(0-100)</Text>
      </FormControl>
    </Flex>
    <Flex>
      <FormControl display="flex" alignItems="center">
        <FormLabel marginBottom="0" mr={4}>
          Enable Alarm Sound
        </FormLabel>
        <Checkbox isChecked={isAlarmSoundEnabled} onChange={(e) => setIsAlarmSoundEnabled(e.target.checked)} />
      </FormControl>
    </Flex>
    <Flex>
      <FormControl display="flex" alignItems="center">
        <FormLabel marginBottom="0" mr={4}>
          Enable White Light
        </FormLabel>
        <Checkbox isChecked={isWhiteLightEnabled} onChange={(e) => setIsWhiteLightEnabled(e.target.checked)} />
      </FormControl>
    </Flex>
    <Button colorScheme="blue" onClick={updateMotion}>
      Save
    </Button>
  </Flex>
  );
};

export default MotionDetection;
