import React, { useState } from 'react';
import {
  Box,
  TableContainer,
  Table,
  Thead,
  Tr,
  Tbody,
  Button,
  Td,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { BsCameraVideo, BsList } from 'react-icons/bs';

const DashboardTable = ({ cameraList, handleOpenModal }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedCamera, setSelectedCamera] = useState(null);
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();

  const handleMouseEnter = (camera) => {
    setSelectedCamera(camera);
    onOpen();
  };

  const handleMouseLeave = () => {
    setSelectedCamera(null);
    onClose();
  };

  return (
    <Box
      height="90vh"
      overflowY="auto"
      css={{
        '&::-webkit-scrollbar': {
          width: '0.2em',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '10px',
        },
        scrollbarWidth: 'thin',
        scrollbarColor: '#888 transparent',
      }}
      borderRadius="16px"
      p={4}
      border="1px solid rgba(255, 255, 255, 0.3)"
    >
      <IconButton
        icon={<BsList />}
        onClick={onDrawerOpen}
        aria-label="Open Camera List"
        bg="none"
        _hover={{ bg: 'none' }}
        _active={{ bg: 'none' }}
        border="none"
        p={0}
      />

      <Drawer isOpen={isDrawerOpen} placement="left" onClose={onDrawerClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Camera List</DrawerHeader>

          <DrawerBody>
            <TableContainer mt={0} p={0} m={0}>
              <Table variant="simple" p={0} m={0}>
                <Thead p={0} m={0}>
                  <Tr p={0} m={0}>
                    {/* Table Headers (if any) */}
                  </Tr>
                </Thead>
                <Tbody p={0} m={0}>
                  {cameraList.map((camera) => (
                    <Tr style={{ display: "flex", padding: 0, margin: 0 }} key={camera.cameraid}>
                      <Button
                        size="sm"
                        onClick={() =>
                          handleOpenModal(
                            camera.streamname,
                            camera.createdDate,
                            camera.plandays,
                            camera.cameraid,
                            camera.cameraname,
                            camera.planname,
                            camera.islive,
                            camera.cameraurl,
                            camera.deviceid
                          )
                        }
                        style={{ padding: 0, margin: 0 }}
                      >
                        <Td style={{ padding: 10, margin: 0 }}>
                          {camera.islive ? (
                            <BsCameraVideo color="green" />
                          ) : (
                            <BsCameraVideo color="red" />
                          )}
                        </Td>
                        <Popover
                          isOpen={isOpen && selectedCamera?.cameraid === camera.cameraid}
                          onOpen={() => handleMouseEnter(camera)}
                          onClose={handleMouseLeave}
                          placement="top"
                          closeOnBlur={false}
                        >
                          <PopoverTrigger>
                            <Td style={{ padding: 0, margin: 0 }}>
                              <Box
                                onMouseEnter={() => handleMouseEnter(camera)}
                                onMouseLeave={handleMouseLeave}
                              >
                                {camera.cameraname}
                              </Box>
                            </Td>
                          </PopoverTrigger>
                          <PopoverContent width="300px">
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverBody p={0}>
                              {selectedCamera && (
                                <iframe
                                  title={selectedCamera.cameraname}
                                  src={`https://${selectedCamera.cameraurl}/${selectedCamera.cameraid}/12_06_2024/${selectedCamera.cameraid}_live.m3u8`}
                                  width="100%"
                                  height="200"
                                  frameBorder="0"
                                  allowFullScreen
                                ></iframe>
                              )}
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Button>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onDrawerClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default DashboardTable;
