import { Box, Button, Divider, Flex, FormControl, FormLabel, Heading, Input, Radio, RadioGroup, Select, Stack } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import axios from "axios";

const DeviceInfo = ({deviceId}) => {

    const[deviceinfo, setDeviceInfo] = useState('')
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get('https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/deviceinfo',{
                params: {
                  deviceid: deviceId
                }
              });
            setDeviceInfo(response.data);
          } catch (error) {
            setError(error);
          }
        };
    
        fetchData();
      }, []); // Empty dependency array means this effect runs once after the initial render
  

    return (
        <Box>
          <Heading as="h3" size="md" mb={4} color="blue.500">
            Device Info
          </Heading>
          <FormControl mb={4}>
            <FormLabel>Device Name</FormLabel>
            <Input type="text" value={deviceinfo.deviceName} disabled/>
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Device Model</FormLabel>
            <Input type="text" value={deviceinfo.model} disabled />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Hardware Version</FormLabel>
            <Input type="text" value={deviceinfo.hardwareVersion} disabled />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Software Version</FormLabel>
            <Input type="text" value={deviceinfo.firmwareVersion} disabled />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Release Time</FormLabel>
            <Input type="text" value={deviceinfo.firmwareReleaseDate} disabled />
          </FormControl>
        </Box>
      );
}

export default DeviceInfo