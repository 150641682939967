import React, { useEffect, useState } from 'react';
import {
  Box, Spinner, Button, Flex, Heading, Stack, Card, CardBody, Text, CardFooter, SimpleGrid,
  useBreakpointValue, useDisclosure, IconButton, Modal, ModalOverlay, ModalContent,
  ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Image
} from '@chakra-ui/react';
import { GiMonkFace } from "react-icons/gi";
import axios from 'axios';
import MobileHeader from '../components/MobileHeader';
import DesktopHeader from '../components/DesktopHeader';
import { FaWalking, FaVideo, FaRunning } from 'react-icons/fa';
import AlertFilters from '../components/AlertFilter'; // Adjust the import path

const AlertsTable = () => {
  const [alerts, setAlerts] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const [playerUrl, setPlayerUrl] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  const pageSize = 18; // Adjust this value as needed
  const cardSize = useBreakpointValue({ base: 'sm', md: 'md', lg: 'lg' });

  const handleCardClick = (device, date, time, event) => {
    const url = `https://ftpa.ambicam.com/${device}/IPC-${device}/picture/${date}/${device}_${time}_${event}.jpg`;
    console.log(url);
    setImageUrl(url);
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const customerId = userDetails?.customerid;

  const fetchAlerts = async (page, filters) => {
    try {
      if (!customerId) {
        throw new Error('Customer ID is not available in localStorage');
      }

      const response = await axios.post('https://adiance-portal-backend-7d9tj.ondigitalocean.app/alert/alerts/customer/', {
        customerid: customerId,
        page: page,
        limit: pageSize,
        ...filters // Include filters in the request
      });

      console.log('Fetched alerts data:', response.data); // Log the fetched data
      setAlerts(response.data.alerts);
      setPagination(response.data.pagination);
    } catch (err) {
      console.error('Error fetching alerts:', err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAlerts(currentPage, filters);
  }, [currentPage, filters]);

  if (loading) {
    return <Box textAlign="center" mt="20"><Spinner size="xl" /></Box>;
  }

  if (error) {
    return <Box textAlign="center" mt="20">Error loading alerts: {error.message}</Box>;
  }

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const month = date.getMonth() + 1; // Months are 0-based
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}_${day}_${year}`;
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
  };

  return (
    <>
      {isMobile ? <MobileHeader /> : <DesktopHeader />}

      <Flex direction="column" ml={{ base: 0, md: '5rem' }} mr={'1rem'}>
      <Flex justify="space-between" align="center" >
          <Heading>Alerts</Heading>
          <AlertFilters onApplyFilters={setFilters} resetPage={setCurrentPage} />
        </Flex>
        {/* <Heading mb={4}>Alerts</Heading>
        <AlertFilters onApplyFilters={setFilters} resetPage={setCurrentPage} /> */}
        <SimpleGrid columns={{ base: 2, sm: 3, md: 4, lg: 6 }} spacing={4}>
          {alerts.map(alert => (
            <Card
              key={alert._id}
              size={cardSize}
              borderWidth="1px"
              borderRadius="md"
              overflow="hidden"
              position="relative"
              color="white"
              _hover={{
                transform: 'scale(1.05)',
                boxShadow: 'lg',
                transition: 'all 0.2s ease-in-out',
              }}
              onClick={() => handleCardClick(alert.deviceSN, formatDate(alert.timeStamp), formatTimestamp(alert.timeStamp), alert.eventType)}
            >
              <Box
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                backgroundImage={`url(https://ftpa.ambicam.com/${alert.deviceSN}/IPC-${alert.deviceSN}/picture/${formatDate(alert.timeStamp)}/${alert.deviceSN}_${formatTimestamp(alert.timeStamp)}_${alert.eventType}.jpg)`}
                backgroundSize="cover"
                backgroundPosition="center"
                opacity={0.3}
                zIndex={0}
              />
              <CardBody>
                <Stack spacing={1}>
                  <Heading color="red.800" size="sm">{alert.deviceSN}</Heading>
                  <Text color="red.800">Time: {alert.timeStamp}</Text>
                  <Box display="flex" alignItems="center" color="red.800">
                    <Text>
                      {alert.eventType === 'MOTION' && (
                        <Button
                          aria-label="Motion Detection"
                          leftIcon={<FaRunning />}
                          size="md"
                          variant="ghost"
                          colorScheme="red"
                          mr={2}
                          _hover={{ bg: 'transparent' }}
                          _focus={{ boxShadow: 'none' }}
                          _active={{ bg: 'transparent' }}
                          bg="transparent"
                        >
                          MOTION DETECTION
                        </Button>
                      )}
                      {alert.eventType === 'HD' && (
                        <Button
                          aria-label="Human Detection"
                          leftIcon={<FaWalking />}
                          size="md"
                          variant="ghost"
                          colorScheme="red"
                          mr={2}
                          _hover={{ bg: 'transparent' }}
                          _focus={{ boxShadow: 'none' }}
                          _active={{ bg: 'transparent' }}
                          bg="transparent"
                        >
                          HUMAN DETECTION
                        </Button>
                      )}
                      {alert.eventType === 'FD' && (
                        <Button
                          aria-label="Face Detection"
                          leftIcon={<GiMonkFace />}
                          size="md"
                          variant="ghost"
                          colorScheme="red"
                          mr={2}
                          _hover={{ bg: 'transparent' }}
                          _focus={{ boxShadow: 'none' }}
                          _active={{ bg: 'transparent' }}
                          bg="transparent"
                        >
                          FACE DETECTION
                        </Button>
                      )}
                      {alert.eventType === 'REGIONENTER' && (
                        <Button
                          aria-label="REGIONENTER"
                          leftIcon={<GiMonkFace />}
                          size="md"
                          variant="ghost"
                          colorScheme="red"
                          mr={2}
                          _hover={{ bg: 'transparent' }}
                          _focus={{ boxShadow: 'none' }}
                          _active={{ bg: 'transparent' }}
                          bg="transparent"
                        >
                          REGION-ENTER
                        </Button>
                      )}
                      {alert.eventType === 'REGIONEXIT' && (
                        <Button
                          aria-label="REGIONEXIT"
                          leftIcon={<GiMonkFace />}
                          size="md"
                          variant="ghost"
                          colorScheme="red"
                          mr={2}
                          _hover={{ bg: 'transparent' }}
                          _focus={{ boxShadow: 'none' }}
                          _active={{ bg: 'transparent' }}
                          bg="transparent"
                        >
                          REGION-EXIT
                        </Button>
                      )}
                      {alert.eventType === 'LINECROSS' && (
                        <Button
                          aria-label="LINECROSS"
                          leftIcon={<GiMonkFace />}
                          size="md"
                          variant="ghost"
                          colorScheme="red"
                          mr={2}
                          _hover={{ bg: 'transparent' }}
                          _focus={{ boxShadow: 'none' }}
                          _active={{ bg: 'transparent' }}
                          bg="transparent"
                        >
                          LINE-CROSS
                        </Button>
                      )}
                    </Text>
                  </Box>
                </Stack>
              </CardBody>

              <CardFooter>
                <Text fontSize="xs" color="red.800">Saved At: {new Date(alert.savestamp).toLocaleString()}</Text>
              </CardFooter>
            </Card>
          ))}
        </SimpleGrid>
        <Flex mt={4} direction="row" justify="space-between">
          <Button
            isDisabled={currentPage === 1}
            onClick={() => setCurrentPage(prevPage => prevPage - 1)}
          >
            Previous
          </Button>
          <Text>{`Page ${currentPage} of ${pagination.totalPages}`}</Text>
          <Button
            isDisabled={currentPage === pagination.totalPages}
            onClick={() => setCurrentPage(prevPage => prevPage + 1)}
          >
            Next
          </Button>
        </Flex>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Alert Image</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image src={imageUrl} alt="Alert Image" />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} colorScheme="red">Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AlertsTable;
