import React, { useState, useEffect } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Select, HStack } from '@chakra-ui/react';

const AlertFilters = ({ onApplyFilters, resetPage }) => {
  const [eventType, setEventType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onApplyFilters({ eventType, startDate, endDate });
  };

  useEffect(() => {
    resetPage(1); // Reset page to 1 on event type change
    onApplyFilters({ eventType, startDate, endDate });
  }, [eventType, startDate, endDate]);

  return (
    <Box p={4} borderRadius="md" mb={4}>
      <form onSubmit={handleSubmit}>
        <HStack spacing={4} align="center">
          <FormControl>
            <FormLabel>Event Type</FormLabel>
            <Select value={eventType} onChange={(e) => setEventType(e.target.value)}>
              <option value="">Event Type</option>
              <option value="MOTION">Motion Detection</option>
              <option value="HD">Human Detection</option>
              <option value="FD">Face Detection</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Start Date</FormLabel>
            <Input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
          </FormControl>
          {/* <FormControl>
            <FormLabel>End Date</FormLabel>
            <Input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
          </FormControl> */}
          <Button pl={10} pr={10} type="submit" colorScheme="red" alignSelf="flex-end">
            Apply Filters
          </Button>
        </HStack>
      </form>
    </Box>
  );
};

export default AlertFilters;
