import React, { useState, useRef, useEffect } from 'react';
import {
  Flex,
  Box,
  Text,
  FormControl,
  FormLabel,
  Checkbox,
  Select,
  Button,
  VStack,
  Input,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb
} from '@chakra-ui/react';

import axios from 'axios';

const LocalSetup = ({deviceId}) => {
    const [networkInterface, setNetworkInterface] = useState('');
    const [dnsSetting, setDnsSetting] = useState('');
    const [macaddress, setMacAddress] = useState('');
    const [error, setError] = useState(null);
  
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get('https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/networkInterface-setting',{
              params: {
                deviceId: deviceId
              }});
            setNetworkInterface(response.data.lan);
          } catch (error) {
            setError(error);
          }
        };
   
        fetchData();
      }, []); // Empty dependency array means this effect runs once after the initial render
     
      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get('https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/dns-setting',{
              params: {
                deviceId: deviceId
              }});
            setDnsSetting(response.data);
          } catch (error) {
            setError(error);
          }
        };
   
        fetchData();
      }, []); // Empty dependency array means this effect runs once after the initial render
  
      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get('https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/mac-address',{
              params: {
                deviceId: deviceId
              }});
              setMacAddress(response.data);
          } catch (error) {
            setError(error);
          }
        };
   
        fetchData();
      }, []); 
   
  return (

    <Flex direction="column" padding="10px" gap={4}>
    <Box>
      <Text fontWeight="bold" fontSize="lg" color="blue.500">
        Local Setup
      </Text>
    </Box>
    <Flex direction="row" align="center" gap={4}>
      <FormControl display="flex" alignItems="center">
        <FormLabel marginBottom="0">MAC Address</FormLabel>
        <Input value={macaddress} isDisabled width="auto" />
      </FormControl>
    </Flex>
    <Flex direction="row" align="center" gap={4}>
      <FormControl display="flex" alignItems="center">
        <FormLabel marginBottom="0" mr={4}>IP Adapted</FormLabel>
        <Checkbox>Open</Checkbox>
        <Checkbox>Close</Checkbox>
      </FormControl>
    </Flex>
    <Flex direction="row" align="center" gap={4}>
      <FormControl display="flex" alignItems="center">
        <FormLabel marginBottom="0" mr={4} >DHCP Switch</FormLabel>
        <Checkbox isChecked={networkInterface.dhcp}>Open</Checkbox>
        <Checkbox isChecked={!networkInterface.dhcp}>Close</Checkbox>
      </FormControl>
    </Flex>
    <Flex direction="row" align="center" gap={4}>
      <FormControl display="flex" alignItems="center">
        <FormLabel fontWeight="bold" marginBottom="0">IP Address</FormLabel>
        <Input value={networkInterface.staticIP} disabled width="auto" />
      </FormControl>
    </Flex>
    <Flex direction="row" align="center" gap={4}>
      <FormControl display="flex" alignItems="center">
        <FormLabel fontWeight="bold" marginBottom="0">Gateway</FormLabel>
        <Input value={networkInterface.staticGateway} disabled width="auto" />
      </FormControl>
    </Flex>
    <Flex direction="row" align="center" gap={4}>
      <FormControl display="flex" alignItems="center">
        <FormLabel fontWeight="bold" marginBottom="0">Netmask</FormLabel>
        <Input value={networkInterface.staticNetmask} disabled width="auto" />
      </FormControl>
    </Flex>
    <Flex direction="row" align="center" gap={4}>
      <FormControl display="flex" alignItems="center">
        <FormLabel fontWeight="bold" marginBottom="0">Preferred DNS</FormLabel>
        <Input value={dnsSetting.preferredDns} isDisabled  width="auto" />
      </FormControl>
    </Flex>
    <Flex direction="row" align="center" gap={4}>
      <FormControl display="flex" alignItems="center">
        <FormLabel fontWeight="bold" marginBottom="0">Alternate DNS</FormLabel>
        <Input value={dnsSetting.staticAlternateDns}  isDisabled width="auto" />
      </FormControl>
    </Flex>
    <Flex direction="row" align="center" gap={4}>
      <FormControl display="flex" alignItems="center">
        <FormLabel fontWeight="bold" marginBottom="0">Port</FormLabel>
        <Input value="80" width="auto" isDisabled />
      </FormControl>
    </Flex>
    <Box mt={4}>
    <Button colorScheme="blue">Save</Button>
  </Box>
  </Flex>

    
);
};

export default LocalSetup;