// src/pages/LoginPage.js
import { Box, Button, Collapse, InputGroup, InputRightElement, Text, Flex, useColorModeValue, Stack, Heading, Checkbox, FormControl, FormLabel, Input, VStack } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import logo from '../assets/images/adiance.png';
import useLoginInstance from '../hooks/LoginPage/LoginForm';
import useSignupInstance from '../hooks/LoginPage/SignupForm';
import useOtpInstance from '../hooks/LoginPage/OtpForm';
import useForgotInstance from '../hooks/LoginPage/ForgotForm';

const LoginPage = () => {
  const [isLoginOpen, setLoginOpen] = useState(true);
  const [isSignUpOpen, setSignUpOpen] = useState(false);
  const [isOtpOpen, setOtpOpen] = useState(false);
  const [isForgotOpen, setForgotOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const handleSignUpClick = () => {
    setLoginOpen(false);
    setSignUpOpen(true);
  };
  const handleForgotClick = () => {
    setLoginOpen(false);
    setForgotOpen(true);
  };

  const { logintitle, loginForm } = useLoginInstance(handleSignUpClick, handleForgotClick);
  
  const handleLoginClick = () => {
    setLoginOpen(true);
    setSignUpOpen(false);
  };

  const handleOtpClick = () => {
    setLoginOpen(false);
    setSignUpOpen(false);
    setOtpOpen(true);
  };

  const { signuptitle, signupForm } = useSignupInstance(handleLoginClick, handleOtpClick);

  const { otptitle, otpForm } = useOtpInstance(setOtpOpen, handleLoginClick);

  const handleLoginClick2 = () => {
    setSignUpOpen(false);
    setForgotOpen(false);
    setLoginOpen(true);
  };

  const { forgottitle, forgotForm } = useForgotInstance(handleLoginClick2);

  return (
    <Flex
      overflowY="hidden"
      minH="100vh"
      align="center"
      justify="center"
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <VStack spacing={4} mx="auto" maxW="lg" p={6} w="100%">
        <img src={logo} alt="Adiance logo" width={isSmallScreen ? 100 : undefined} height={isSmallScreen ? 'auto' : undefined} />
        {isLoginOpen && (
          <Heading fontSize="2xl" textAlign="center" color="primary.500">{logintitle}</Heading>
        )}
        {isSignUpOpen && (
          <Heading fontSize="2xl" textAlign="center" color="primary.500">{signuptitle}</Heading>
        )}
        {isOtpOpen && (
          <Heading fontSize="2xl" textAlign="center" color="primary.500">{otptitle}</Heading>
        )}
        {isForgotOpen && (
          <Heading fontSize="2xl" textAlign="center" color="primary.500">{forgottitle}</Heading>
        )}
        <Text fontSize="md" color="gray.600" textAlign="center">
          to enjoy all of our cool <span style={{ color: "#4299E1" }}>features</span>
        </Text>
        <Box
          rounded="lg"
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow="lg"
          p={6}
          w="100%"
        >
          {isLoginOpen && <>{loginForm}</>}
          {isSignUpOpen && <>{signupForm}</>}
          {isOtpOpen && <>{otpForm}</>}
          {isForgotOpen && <>{forgotForm}</>}
        </Box>
      </VStack>
    </Flex>
  );
};

export default LoginPage;
