import React, { useState, useRef, useEffect } from 'react';
import {
  Flex,
  Box,
  Text,
  FormControl,
  FormLabel,
  Checkbox,
  Select,
  Button,
  VStack,
  Input,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb
} from '@chakra-ui/react';

import axios from 'axios';


const RTMPSetup = ({deviceId}) => {
   
  return (

    <Flex direction="column" padding="10px" gap={4}>
            <Box>
              <Text fontWeight="bold" fontSize="lg" color="blue.500">
                RTMP Setup
              </Text>
            </Box>
            <Flex direction="row" align="center" gap={4}>
              <FormControl display="flex" alignItems="center">
                <FormLabel marginBottom="0" mr={4}>
                  RTMP Switch
                </FormLabel>
                <Checkbox />
              </FormControl>
            </Flex>
            <Flex direction="row" align="center" gap={4}>
              <FormControl display="flex" alignItems="center">
                <FormLabel marginBottom="0">RTMP URL</FormLabel>
                <Input value="" width="auto" />
              </FormControl>
            </Flex>
            <Flex direction="row" align="center" gap={4}>
              <FormControl display="flex" alignItems="center">
                <FormLabel marginBottom="0">
                  Stream
                </FormLabel>
                <Select width="auto" placeholder="Select Gateway">
                  <option value="MainStream">main stream</option>
                  <option value="Substream">sub stream1</option>
                </Select>
              </FormControl>
            </Flex>
            <Box mt={4}>
          <Button colorScheme="blue">Save</Button>
        </Box>
          </Flex>
    
);
};

export default RTMPSetup;