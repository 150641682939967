import React, { useState, useRef, useEffect } from 'react';
import {
  Flex,
  Box,
  Text,
  FormControl,
  FormLabel,
  Checkbox,
  Select,
  Button
} from '@chakra-ui/react';
import CanvasModal from './LineCrossCanvas';
import axios from 'axios';
import LineCrossCanvas from './LineCrossCanvas';

const Humanoid = ({deviceId}) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [alarmSound, setAlarmSound] = useState(false);
  const [spOSD, setspOSD] = useState(false);
  const [Sensitivity, setSensitivity] = useState();

  const [whiteLightAlarm, setWhiteLightAlarm] = useState(false);
  const [appMessagePush, setAppMessagePush] = useState(false);
  const [rtmpPush, setRtmpPush] = useState(false);
  const [ftpPush, setFtpPush] = useState(false);
  const [error, setError] = useState(null);
  const [detectLine, setDetectLine] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        
        const response = await axios.get('https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/getHumanoid',{
          params: {
            deviceId: deviceId
          }});
         
          setIsEnabled(response.data.Enabled)
          setspOSD(response.data.spOSD)
          setSensitivity(response.data.Sensitivity)
          setRtmpPush(response.data.AlarmOut.RtmpPush.Enabled)
          setFtpPush(response.data.AlarmOut.FtpPush.Enabled)
          setAlarmSound(response.data.AlarmOut.AudioAlert.Enabled)
          setWhiteLightAlarm(response.data.AlarmOut.LightAlert.Enabled)
          setAppMessagePush(response.data.AlarmOut.AppPush.Enabled)
          setDetectLine(response.data.DetectAera);
   
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, [deviceId]); 

  const handleSave = async () => {
    try {
      const response = await axios.put(`https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/setHumanoid`, {
        Enabled: isEnabled,
        spOSD:spOSD,
        Sensitivity:Sensitivity,
        AlarmOut: {
          AudioAlert: {
            Enabled: alarmSound,
          },
          LightAlert: {
            Enabled: whiteLightAlarm,
          },
          AppPush: {
            Enabled: appMessagePush,
          },
          RtmpPush: {
            Enabled: rtmpPush,
          },
          FtpPush: {
            Enabled: ftpPush,
          },
        }
      }, {
        params: { deviceId: deviceId }
      });

      console.log('Settings saved', response.data);
    } catch (error) {
      console.error('Error saving settings', error);
    }
  };



  return (
      <Flex direction="column" padding="10px" gap={4}>
            <Box>
              <Text fontWeight="bold" fontSize="lg" color="blue.500">
                Humanoid Detection
              </Text>
            </Box>
            <Flex>
              <FormControl display="flex" alignItems="center">
                <FormLabel marginBottom="0" mr={4}>
                  Enable
                </FormLabel>
                <Checkbox isChecked={isEnabled} onChange={(e) => setIsEnabled(e.target.checked)} />
              </FormControl>
            </Flex>
            <Flex>
              <FormControl display="flex" alignItems="center">
                <FormLabel marginBottom="0" mr={4}>
                  Enable alarm Sound
                </FormLabel>
                <Checkbox isChecked={alarmSound} onChange={(e) => setAlarmSound(e.target.checked)} />
              </FormControl>
            </Flex>
            <Flex>
              <FormControl display="flex" alignItems="center">
                <FormLabel marginBottom="0" mr={4}>
                  Enable white light alarm sound
                </FormLabel>
                <Checkbox isChecked={whiteLightAlarm} onChange={(e) => setWhiteLightAlarm(e.target.checked)} />
              </FormControl>
            </Flex>
            <Flex>
              <FormControl display="flex" alignItems="center">
                <FormLabel marginBottom="0" mr={4}>
                  Enable app message push
                </FormLabel>
                <Checkbox isChecked={appMessagePush} onChange={(e) => setAppMessagePush(e.target.checked)} />
              </FormControl>
            </Flex>
            <Flex>
              <FormControl display="flex" alignItems="center">
                <FormLabel marginBottom="0" mr={4}>
                  Enable RTMP push
                </FormLabel>
                <Checkbox isChecked={rtmpPush} onChange={(e) => setRtmpPush(e.target.checked)} />
              </FormControl>
            </Flex>
            <Flex>
              <FormControl display="flex" alignItems="center">
                <FormLabel marginBottom="0" mr={4}>
                  Enable FTP push
                </FormLabel>
                <Checkbox isChecked={ftpPush} onChange={(e) => setFtpPush(e.target.checked)} />
              </FormControl>
            </Flex>
            <Flex>
              <FormControl display="flex" alignItems="center">
                <FormLabel marginBottom="0" mr={4}>
                  Humanoid Draw Region
                </FormLabel>
                <Checkbox isChecked={spOSD} onChange={(e) => setspOSD(e.target.checked)} />
              </FormControl>
            </Flex>
            <Flex direction="row" align="center" gap={4}>
              <FormControl display="flex" alignItems="center">
                <FormLabel marginBottom="0">Sensitivity Level</FormLabel>
                <Select width="auto" value={Sensitivity} onChange={(e) => setSensitivity(Number(e.target.value))}>
            {Array.from({ length: 11 }, (_, i) => (
              <option key={i} value={i}>
                {i}
              </option>
            ))}
          </Select>
              </FormControl>
            </Flex>
            <Box mt={4}>
          <Button colorScheme="blue" onClick={handleSave}>Save</Button>
        </Box>
          </Flex>
     
  );
};

export default Humanoid;
