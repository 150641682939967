import axios from 'axios';
import { parseStringPromise } from 'xml2js';

const baseURL = 'https://adiance-portal-backend-7d9tj.ondigitalocean.app'; // Set your API base URL here 

const instance = axios.create({
  baseURL: baseURL
});

export async function getShareCameraList(resultPerPage,page,email) {
  try {
    const token = localStorage.getItem('token');
    console.log(email,resultPerPage,page)
    const response = await instance.get('/camera/sharecamera', {
      params: {
        email: email,
        page: page,
        resultPerPage:resultPerPage,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },

    });
    return response.data;

  } catch (error) {
    throw error;
  }
}

