import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Badge,
  Image,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  VStack,
  Flex,
  Select,
  FormControl,
  FormLabel,
  Input,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Stack,
  Checkbox,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Heading
} from '@chakra-ui/react';
import { FiMoreVertical, FiShare, FiTrash2, FiSettings, FiChevronLeft, FiChevronRight, FiChevronUp, FiChevronDown, FiPlay, FiDownload } from 'react-icons/fi';
import JessibucaPlayer from "react-jessibuca"
// import SDCardDataTable from './SDCardDataTable';
import { getSDCardStatus,getSDCardData } from '../services/sdcard';

const SDCardDataTable = ({ deviceId }) => {
  const [data, setData] = useState(null)
  const [checkedItems, setCheckedItems] = useState({
    Timer: false,
    Motion: false,
    Human: false,
    Face: false,
    Linecross: false,
    RegionEnter: false,
    RegionExit: false,
    Intrude: false,
    Unattend: false,
    ObjRemove: false,
  });

  const [error, setError] = useState(null);
  const pageSize = 10; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1); 
  const [selectedCameraUrl, setSelectedCameraUrl] = useState('');
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSDCardData(currentPage,deviceId,selectedDate);
        setData(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, [currentPage,deviceId,selectedDate]);
  if (!data) {
    return <Text>Loading...</Text>;
  }

  const handleSelectAll = () => {
    const allChecked = Object.keys(checkedItems).reduce((items, item) => {
      items[item] = true;
      return items;
    }, {});
    setCheckedItems(allChecked);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  


  const totalPages = Math.ceil(data.SearchCnt / data.ReadCnt);
  // Slice data to show items for current page
  const startIndex = (currentPage - 1) * pageSize;
  const itemsToShow = data.Item.slice(startIndex, startIndex + pageSize);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePlay = (item) => {
    setSelectedCameraUrl(item); // Replace with actual property name from your data
    setIsVideoModalOpen(true);
  };

  // Function to close video modal
  const handleVideoCloseModal = () => {
    setIsVideoModalOpen(false);
    setSelectedCameraUrl(''); // Reset selected URL
  };

  if (!data || !data.Item || data.Item.length === 0) {
    return <Text>No data available</Text>;
  }

  const handleDownload = (item) => {
    const downloadUrl = `https://web1.torqueverse.dev/mp4/pb?pb_${item.TimeStart}_${item.TimeEnd}_ch0_0.mp4?verify=a/b4Znt+OFGrYtmHw0T16Q==`;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = `video_${item.TimeStart}_${item.TimeEnd}.mp4`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  return (
    <>
      <Flex direction="column" padding="10px" align="center">
      <FormControl>
          <Input
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
          />
        </FormControl>
        {/* <Flex direction="row" wrap="wrap" gap={2}>
          {Object.keys(checkedItems).map((item) => (
            <Box key={item}>
              <Checkbox
                name={item}
                isChecked={checkedItems[item]}
                onChange={handleCheckboxChange}
              >
                {item}
              </Checkbox>
            </Box>
          ))}
        </Flex> */}
        {/* <Flex direction="row" wrap="wrap" gap={2} mb={4}>
          <Box>
            <Button colorScheme="blue" mr={2} onClick={handleSelectAll}>
              Select All
            </Button>
            <Button colorScheme="blue">Search</Button>
          </Box>
        </Flex> */}
        <Box width="100%" mt={4}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Index</Th>
                <Th>Type</Th>
                <Th>Start Time</Th>
                <Th>End Time</Th>
                <Th>Operations</Th>
              </Tr>
            </Thead>
            <Tbody>
            {data.Item.map((item, index) => (
            <Tr key={index}>
              <Td>{index + 1}</Td>
              <Td>{item.Type}</Td>
              <Td>{new Date(item.TimeStart * 1000).toLocaleString('en-US', { timeZone: 'GMT' })}</Td>
              <Td>{new Date(item.TimeEnd * 1000).toLocaleString('en-US', { timeZone: 'GMT' })}</Td>
              <Td>{calculateDuration(item.TimeStart, item.TimeEnd)}</Td>
              <Td>
                <HStack spacing="2">
                  <IconButton
                    icon={<FiPlay />}
                    aria-label="Play"
                    onClick={() => handlePlay(`https://${deviceId}.torqueverse.dev/flv/pb?pb_${item.TimeStart}_${item.TimeEnd}_ch0_0.flv?verify=a/b4Znt+OFGrYtmHw0T16Q==`)}
                    size="sm"
                  />
                  {/* <IconButton
                    icon={<FiDownload />}
                    aria-label="Download"
                    onClick={() => handleDownload(item)}
                    size="sm"
                  /> */}
                </HStack>
              </Td>
            </Tr>
          ))}
            </Tbody>
          </Table>
        </Box>

        <Flex mt={4}>
          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <Text mx={2}>
            Page {currentPage} of {totalPages}
          </Text>
          <Button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </Flex>

        <Modal isOpen={isVideoModalOpen} onClose={handleVideoCloseModal} size="3xl">
        <ModalOverlay />
        <ModalContent bg="white" color="black">
          <ModalHeader>Camera Video</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <div style={{ width: '100%', height: '500px' }}>
            <JessibucaPlayer
              controls={{
                fullscreen: true,
                screenshot: true,
                play: true,
                audio: true,
                record: true,
              }}
              src={selectedCameraUrl}
              decoder="/decoder.js"
              style={{ width: '100%', height: '100%' }}
            />
          </div>
          </ModalBody>
          <ModalFooter>
            <Text mr={4}>Camera ID: {/* Replace with camera ID from props */}</Text>
            <Button colorScheme="red" onClick={handleVideoCloseModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      </Flex>
    </>
  );
};
const calculateDuration = (startTime, endTime) => {
  const start = new Date(startTime * 1000);
  const end = new Date(endTime * 1000);
  const durationInSeconds = (end - start) / 1000;
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = Math.floor(durationInSeconds % 60);
  return `${hours}h ${minutes}m ${seconds}s`;
};
export default SDCardDataTable;

