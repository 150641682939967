import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/dashboard';
import CameraFeedsPage from './pages/multiple';
import theme from './assets/styles/theme';
import AlertsTable from './pages/AlertPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import RefundAndCancellation from './pages/RefundAndCancellation';
import TermsAndConditions from './pages/TermsAndConditions';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check if user is logged in (e.g., check local storage or API)
    const loggedInStatus = localStorage.getItem('isLoggedIn') === 'true';
    setIsLoggedIn(loggedInStatus);
  }, []);


  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={isLoggedIn ? <Navigate to="/dashboard" /> : <LoginPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/multiple" element={<CameraFeedsPage />} />
          <Route path="/alert" element={<AlertsTable />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/refundandcancellation" element={<RefundAndCancellation />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
