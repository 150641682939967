import React, { useState, useRef, useEffect } from 'react';
import {
  Flex,
  Box,
  Text,
  FormControl,
  FormLabel,
  Checkbox,
  Select,
  Button,
  VStack,
  Input
} from '@chakra-ui/react';

import axios from 'axios';


const VideoSetup = ({deviceId}) => {
    const [microphoneType, setMicrophoneType] = useState('');
    const [profile, setProfile] = useState('');
    const [encodeType, setEncodeType] = useState('');
    const [stream, setStream] = useState('');
    const [resolution, setResolution] = useState('');
    const [bitrateControlType, setBitrateControlType] = useState('');
    const [channelName, setChannelName] = useState('');
    const [bps, setBps] = useState('');
    const [fps, setFps] = useState('');
    const [data, setData] = useState('');
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
          try {
            
            const response = await axios.get('https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/get-videoSettings',{
              params: {
                deviceid: deviceId
              }
            });
            console.log(response.data)
            setData(response.data);
          } catch (error) {
            setError(error);
          }
        };
       
        fetchData();
      }, [deviceId]);


      const handleMediaContent = () => {
        const data = {
          deviceId:deviceId,
          codecType: profile,
          h264Profile: "high", // This value is static, adjust as necessary
          resolution: resolution,
          freeResolution: false,
          bitRateControlType: bitrateControlType,
          constantBitRate: parseInt(bps, 10), // Ensure BPS is a number
          frameRate: parseInt(fps, 10) // Ensure FPS is a number
        };
     
        const config = {
          headers: {
            Authorization: 'Basic ' + btoa('admin:'), // Assuming 'admin' is the username with no password
            'Content-Type': 'application/json'
          }
        };
     
        axios.put('https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/video-setup', data, config)
          .then(response => {
            console.log('Response:', response);
          })
          .catch(error => {
            console.error('Error:', error);
          });
      };

  return (

    <VStack spacing={4} align="stretch">
    <FormControl>
      <FormLabel>Microphone Type</FormLabel>
      <Select value={microphoneType} onChange={(e) => setMicrophoneType(e.target.value)}>
        <option value="Active Pickup">Active Pickup</option>
        <option value="Passive Mic">Passive Mic</option>
      </Select>
    </FormControl>
    <FormControl>
      <FormLabel>Profile</FormLabel>
      <Select value={data.codecType} onChange={(e) => setProfile(e.target.value)}>
        <option value="H.264">H.264</option>
        <option value="H.265">H.265</option>
        <option value="H.264+">H.264+</option>
        <option value="H.265+">H.265+</option>
      </Select>
    </FormControl>
    <FormControl>
      <FormLabel>Encode Type</FormLabel>
      <Select value={encodeType} onChange={(e) => setEncodeType(e.target.value)}>
        <option value="video">video</option>
        <option value="audio&video">audio&video</option>
      </Select>
    </FormControl>
    <FormControl>
      <FormLabel>Stream</FormLabel>
      <Select value={stream} onChange={(e) => setStream(e.target.value)}>
        <option value="main stream">main stream</option>
        <option value="substream1">substream1</option>
      </Select>
    </FormControl>
    <FormControl>
      <FormLabel>Resolution</FormLabel>
      <Select value={data.resolution} onChange={(e) => setResolution(e.target.value)}>
        <option value="2304x1296">2304x1296</option>
        <option value="1920x1080">1920x1080</option>
        <option value="1280x720">1280x720</option>
      </Select>
    </FormControl>
    <FormControl>
      <FormLabel>Bitrate Control Type</FormLabel>
      <Select value={data.bitRateControlType} onChange={(e) => setBitrateControlType(e.target.value)}>
        <option value="vbr">VBR</option>
        <option value="cbr">CBR</option>
      </Select>
    </FormControl>
    <FormControl>
      <FormLabel>Channel Name</FormLabel>
      <Input type="text" value={channelName} onChange={(e) => setChannelName(e.target.value)} />
    </FormControl>
    <FormControl>
      <FormLabel>BPS</FormLabel>
      <Input type="text" value={data.constantBitRate} onChange={(e) => setBps(e.target.value)} />
    </FormControl>
    <FormControl>
      <FormLabel>FPS</FormLabel>
      <Input type="text" value={data.frameRate} onChange={(e) => setFps(e.target.value)} />
    </FormControl>
      <Button onClick={handleMediaContent}>Apply</Button>
      {/* <Button onClick={()=> console.log(data)}>response</Button> */}
  </VStack>

    // <Flex direction="column" padding="10px" gap={4}>
    //   <Box>
    //     <Text fontWeight="bold" fontSize="lg" color="blue.500">
    //       Line_Crossing_Detection
    //     </Text>
    //   </Box>
    //   <Flex>
    //     <FormControl display="flex" alignItems="center">
    //       <FormLabel marginBottom="0" mr={4}>
    //         Enable
    //       </FormLabel>
    //       <Checkbox isChecked={isEnabled} onChange={(e) => setIsEnabled(e.target.checked)} />
    //     </FormControl>
    //   </Flex>
    //   <Flex>
    //     <FormControl display="flex" alignItems="center">
    //       <FormLabel marginBottom="0" mr={4}>
    //         Enable_alarn_sound
    //       </FormLabel>
    //       <Checkbox isChecked={alarmSound} onChange={(e) => setAlarmSound(e.target.checked)} />
    //     </FormControl>
    //   </Flex>
    //   <Flex>
    //     <FormControl display="flex" alignItems="center">
    //       <FormLabel marginBottom="0" mr={4}>
    //         Enable_white_light_alarm
    //       </FormLabel>
    //       <Checkbox isChecked={whiteLightAlarm} onChange={(e) => setWhiteLightAlarm(e.target.checked)} />
    //     </FormControl>
    //   </Flex>
    //   <Flex>
    //     <FormControl display="flex" alignItems="center">
    //       <FormLabel marginBottom="0" mr={4}>
    //         Enable_App_message_push
    //       </FormLabel>
    //       <Checkbox isChecked={appMessagePush} onChange={(e) => setAppMessagePush(e.target.checked)} />
    //     </FormControl>
    //   </Flex>
    //   <Flex>
    //     <FormControl display="flex" alignItems="center">
    //       <FormLabel marginBottom="0" mr={4}>
    //         Enable_Rtmp_push
    //       </FormLabel>
    //       <Checkbox isChecked={rtmpPush} onChange={(e) => setRtmpPush(e.target.checked)} />
    //     </FormControl>
    //   </Flex>
    //   <Flex>
    //     <FormControl display="flex" alignItems="center">
    //       <FormLabel marginBottom="0" mr={4}>
    //         Enable_Ftp_push
    //       </FormLabel>
    //       <Checkbox isChecked={ftpPush} onChange={(e) => setFtpPush(e.target.checked)} />
    //     </FormControl>
    //   </Flex>
      
    //   <Box mt={4}>
    //     <Button colorScheme="blue" onClick={handleSave}>
    //       SaveLineCrossingDetectionSettings
    //     </Button>
    //   </Box>
      
    //   <Box mt={4}>
    //     <Button colorScheme="teal" onClick={openCanvasModal}>
    //       Open Canvas
    //     </Button>
    //   </Box>
    //   <LineCrossCanvas isOpen={isCanvasModalOpen}  onClose={closeCanvasModal} onCanvasData={handleCanvasData} 
    //   existingCoordinates={detectLine}
    //   existingDirection={direction}
    //   />
    // </Flex> 
  );
};

export default VideoSetup;
