import React, { useEffect, useState,useRef,useCallback  } from 'react';

import { getCustomerCameraList } from '../services/getcamera';
import { getShareCameraList } from '../services/getsharecamera';
import { ShareCamera ,deleteShareCamera} from '../services/sharethiscamera';
import { useNavigate } from 'react-router-dom';
import LiveFeed from '../components/LiveFeed-Hls';
import withAuth from '../components/withAuth';
import { useMediaQuery } from '@chakra-ui/react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  IconButton,
  Spacer,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Grid,
  GridItem,
  Image,Badge,
  Menu, MenuButton, MenuList, MenuItem,
  useDisclosure,AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogBody,
  AlertDialogCloseButton,
  HStack,
  Link,  Modal,
  ModalOverlay,
  ModalContent,Icon,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,FormControl,FormLabel,Input,Spinner 
} from '@chakra-ui/react';
import { FiMenu, FiSettings, FiBell, FiX,FiTrash2,FiMoreVertical ,FiEdit,FiRefreshCcw, FiShare, FiShare2   } from 'react-icons/fi';
import { RiSdCardMiniFill } from "react-icons/ri";
import {RiPlayList2Line} from 'react-icons/ri'
import {MdOutlineQrCodeScanner ,MdOutlineAddAPhoto} from 'react-icons/md'
import { BiX } from 'react-icons/bi';
import { FiInfo,FiDownload } from 'react-icons/fi';
import {BsCameraVideo} from 'react-icons/bs'
import DashboardTable from '../components/DashboardTable';
import CameraSettings from '../components/CameraSettings';
import { SettingsIcon } from '@chakra-ui/icons';
import 'react-calendar/dist/Calendar.css';
import { addCamera } from '../services/addcamera';
import { deleteCamera } from '../services/deletecamera';
import { DateTime }  from 'luxon';
import OfflineMessage from "../components/OfflineMessage"
import { FaVideoSlash,FaSortAmountDownAlt } from 'react-icons/fa';
import { Capacitor } from '@capacitor/core';
import JessibucaPlayer from "react-jessibuca"
import { Browser } from '@capacitor/browser';
import QRCodeScanner from '../components/QRCodeScanner';
import CardModel from '../components/Card';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { logout as apiLogout } from '../services/auth';
import SDCardDataTable from '../components/SDCardDataTable';
import Psettings from '../components/Psettings';
import CameraPTZ from '../components/CameraPTZ';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { TbZoomReset } from 'react-icons/tb';
import { TransformWrapper, TransformComponent, useControls } from "react-zoom-pan-pinch";
import WebSocketComponent from '../components/WebSocketComponent';

const timeZoneId = 'Asia/Kolkata';


const CameraList = ( ) => {
  const router = useNavigate();
  const [cameraList, setCameraList] = useState([]);
  const [shareCameraList, setShareCameraList] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [islive,setisLive] = useState('');
  const [streamid,setStreamid]=useState('')
  const [cameraId,setCameraid]=useState('')
  const [cameraname,setCameraname]=useState('');
  const [planname,setPlanname]=useState(false)
  const [cameraurl,setCameraurl]=useState('')
  const [isLoading, setIsLoading] = useState(true);
  const [isScanning, setIsScanning] = useState(false);
  const [startDateTime, setStartDateTime] = useState(''); // Format: YYYY-MM-DDTHH:MM
  const [endDateTime, setEndDateTime] = useState(''); // Format: YYYY-MM-DDTHH:MM
  const [isDownloading,setIsDownloading]=useState('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [cameraToDelete, setCameraToDelete] = useState(null);
  const [videoLoadError, setVideoLoadError] = useState(false);
  const [plantext,setPlantext] = useState('')
  const [deviceId,setDeviceId] = useState('')
  const [totalPages,setTotalPages] = useState(1);
  const [page,setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState();
  const [totalitem, setTotalitem] = useState(0);
  const [isload,setIsload]=useState(false);
  const [receiveremail,setIsReceiverEmail] =useState('');
  const [isAddCameraModalOpen, setIsAddCameraModalOpen] = useState(false);
  const [isShareCameraModalOpen, setIsShareCameraModalOpen] = useState(false);
  const [newCameraName, setNewCameraName] = useState('');
  const [newCameraId, setNewCameraId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isTabOpen, setTabOpen] = useState(false);

  const toast = useToast()



  



  const Next = () => { 
    if (page < totalPages) {
      setPage(page + 1);
    }
  }
  
  const Previous = () => {
    if (page > 1) {
      setIsLoading(true)
      setPage(page - 1);
    }
  }
  
  useEffect(() => {
    const changeHoverText = () => {
      const elements = document.querySelectorAll('.icon-title-tips .icon-title');
      elements.forEach(element => {
        if (element.textContent === '暂停') {
          element.textContent = 'Pause';
        }
        if (element.textContent === '截图') {
          element.textContent = 'ScreenSort';
        }
        if (element.textContent === '录制') {
          element.textContent = 'Video';
        }
        if (element.textContent === '全屏') {
          element.textContent = 'FullScreen';
        }
        if (element.textContent === '退出全屏') {
          element.textContent = 'Exit';
        }
        if (element.textContent === '播放') {
          element.textContent = 'Play';
        }
        if (element.textContent === '停止录制') {
          element.textContent = 'Stop Recording';
        }
        // 退出全屏
      });
    };

    // Create a MutationObserver to monitor changes in the DOM
    const observer = new MutationObserver(() => {
      changeHoverText();
    });

    // Observe the body for changes in its child elements
    observer.observe(document.body, { childList: true, subtree: true });

    // Initial run to catch elements already in the DOM
    changeHoverText();

    // Cleanup the observer when the component is unmounted
    return () => observer.disconnect();
  }, []);

  const fetchData = async (page, updateOnlyStatus = false) => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const customerId = userDetails.customerid;
    console.log(userDetails);
    let resultPerPage = 9;
    if (window.innerWidth > 1645) {
      resultPerPage = 12; // Set to 12 if the screen width is greater than 1645px
    }
    if (!updateOnlyStatus && page > 1) {
      setIsLoading(false);
    } else if (!updateOnlyStatus) {
      setIsLoading(true);
    }
  
    try {
      // Fetch status data
      const statusResponse = await fetch('https://adiance-portal-backend-7d9tj.ondigitalocean.app/status');
      const statusData = await statusResponse.json();
  
      if (!updateOnlyStatus) {
        // Fetch camera list data
        const result = await getCustomerCameraList(customerId, page, resultPerPage);
        if (result.success) {
          // Merge the status data with camera list data
          const updatedCameraList = result.cameras.map(camera => {
            const matchingStatus = statusData.proxies.find(proxy => 
              proxy.name.trim().toLowerCase() === camera.deviceid.trim().toLowerCase()
            );
            return {
              ...camera,
              status: matchingStatus ? matchingStatus.status : 'unknown' // Add status field or default to 'unknown'
            };
          });
  
          if (page > 1) {
            setCameraList((prevCameraList) => [...prevCameraList, ...updatedCameraList]);
          } else {
            setCameraList(updatedCameraList);
          }
  
          setTotalitem(result.totalItems);
          setTotalPages(result.totalPages);
          console.log('cameralist', updatedCameraList);
          setIsLoading(false);
          setIsload(false); // for scroll down event
        } else {
          if (result.message === 'Invalid token or authorization') {
            // Call the logout function and redirect to the login page
            handleLogout(router);
          }
        }
      } else {
        // If only updating status, update the existing camera list
        setCameraList(prevCameraList => prevCameraList.map(camera => {
          const matchingStatus = statusData.proxies.find(proxy =>
            proxy.name.trim().toLowerCase() === camera.deviceid.trim().toLowerCase()
          );
          return {
            ...camera,
            status: matchingStatus ? matchingStatus.status : 'unknown'
          };
        }));
      }
  
      if (!updateOnlyStatus) {
        setIsLoading(false);
        setIsload(false); // For scroll down event
      }
    } catch (error) {
      console.error('Error fetching camera list:', error);
  
      // Handle errors by checking the error message
      if (error.response && error.response.data.message === 'Invalid token or authorization') {
        // Call the logout function and redirect to the login page
        handleLogout(router);
      }
  
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Initial fetch of data
    fetchData(1);
  
    // Set up interval to check status every 5 seconds
    const statusInterval = setInterval(() => {
      fetchData(1, true); // Pass true to update only the status
    }, 5000);
  
    // Clear interval on component unmount
    return () => clearInterval(statusInterval);
  }, []);

    const handleLogout = async (router) => {
      // Call the API logout function
      const response = await apiLogout();
  
      if (response.success) {
          // Clear local storage or session storage
          localStorage.removeItem('userDetails');
          localStorage.removeItem('isLoggedIn');
  
          // Redirect to the login page
          router('/');
      } else {
          console.error('Error logging out:', response.message);
      }
  };

    
    useEffect(() => {
      fetchData(page);
    }, [page]);
    
    // Your scrolling function
    const handleScroll = (event) => {
      const { scrollTop, scrollHeight, clientHeight } = event.target;

  
      if (scrollTop + clientHeight >= scrollHeight - 1  ) {
        setIsload(true);
        
        if( cameraList.length < totalitem ){
          Next();
        }
        else{
          setIsload(false);
        }
        
      }
    };
 
  

const [sharecameraname,setShareCameraName] = useState('')
const [sharecameraid,setShareCameraId] = useState('')



  const handleShareCameraModel = (cameraname, cameraid) => {
    setIsShareCameraModalOpen(true);
    setShareCameraName(cameraname)
    setShareCameraId(cameraid)
  };



  const handleDeleteCamera = (cameraId,deviceId) => {

    setCameraToDelete(cameraId,deviceId);
    setIsDeleteDialogOpen(true);
  };
    
  

    const [sortByLive, setSortByLive] = useState(false);

    const sortedCameraList = [...cameraList].sort((a, b) => {
      if (sortByLive) {
          if (a.islive && !b.islive) return -1;
          if (!a.islive && b.islive) return 1;
       }
      else {
        //This Will Load Without Filter
      } 
      return 0;
    });


    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
  
      handleResize(); // Initial check
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  

   


  
    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setDeviceWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const [isDesktop] = useMediaQuery('(min-width: 1600px)');

    // Define the modal size based on the screen size
    const modalSize = isDesktop ? ['full', 'full', 'full', '5xl'] : ['full', 'full', 'full', '3xl'];

    // console.log(selectedDate)
    const [imageHeight, setImageHeight] = useState('');
    useEffect(() => {
      const handleResize = () => {
        const windowWidth = window.innerWidth;
        
        if (windowWidth <= 480 && windowWidth >= 420) {
          setImageHeight('');
        } else if (windowWidth < 420 && windowWidth >= 350) {
          setImageHeight('');
        } else if (windowWidth < 350) {
          setImageHeight('180px');
        } else {
          setImageHeight(''); // Reset to default height when none of the conditions match
        }
      };
     
      // Call handleResize initially and add a listener for window resize events
      handleResize();
      window.addEventListener('resize', handleResize);
  
      // Clean up the listener when the component unmounts
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  


    


  return (
    <>
       <Flex  flexDirection={{ base: 'column', md: 'row' }} 
      flexWrap="wrap"
      align="stretch"
      height="100%"
        >



        {/* Content */}
        <Box flex={6} p={deviceWidth < 290 ? 0 : '10px 4px 0px 4px'}>
          {  !isMobile && (
            <Flex mb={isMobile ? 2 : 4} align="center">
         
          <Spacer />     
            </Flex>

          )}

<Box
              onScroll={handleScroll}
              maxH={isMobile ? 'calc(100vh - 170px)' : 'calc(100vh - 230px)'}
              overflowY="auto"
              css={{
                '&::-webkit-scrollbar': {
                  width: '0.2em',
                },
                '&::-webkit-scrollbar-track': {
                  background: 'transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#888',
                },
                overflowX: 'hidden', // Hide horizontal scrollbar
                scrollbarWidth: 'thin', // For Firefox
                scrollbarColor: '#888 transparent', // For Firefox
                ':active': {
                  overflowY: 'scroll', // Show scrollbar while touched
                },
              }}
              bg="rgba(255, 255, 255, 0.1)"
              backdropFilter="blur(10px)"
              borderRadius="16px"
              p={4}
              boxShadow="0 4px 30px rgba(0, 0, 0, 0.1)"
              border="1px solid rgba(255, 255, 255, 0.3)"
            >
              {cameraList.length === 0 ? (
                <Flex alignItems="center" justifyContent="center" height="100%">
                  <Box
                    p={6}
                    borderWidth={1}
                    borderRadius="lg"
                    borderColor="gray.300"
                    boxShadow="md"
                    bg="rgba(255, 255, 255, 0.1)"
                    backdropFilter="blur(10px)"
                  >
                    <Flex direction="column" alignItems="center" color="gray.500">
                      <Icon as={FaVideoSlash} boxSize={20} color="blue.500" />
                      <Text fontSize="xl" mt={4} fontWeight="bold">
                        Oops! No cameras found.
                      </Text>
                      <Text fontSize="md" mt={2} textAlign="center">
                        Looks like you haven't added any cameras yet. Start by adding a camera to your list.
                      </Text>
                      <Button colorScheme="blue" mt={4} size="sm" onClick={() => setIsAddCameraModalOpen(true)}>
                        Add Camera
                      </Button>
                    </Flex>
                  </Box>
                </Flex>
              ) : (
                <Grid templateColumns="repeat(auto-fill, minmax(480px, 1fr))" gap={4}>
                  {sortedCameraList.map((camera) => (
                    <GridItem key={camera.cameraid} width={window.innerWidth <= 280 ? '280px' : ''}>
                      <Box
                        bg="rgba(255, 255, 255, 0.1)"
                        backdropFilter="blur(10px)"
                        borderRadius="md"
                        p={2}
                        mb={2}
                        boxShadow="0 4px 30px rgba(0, 0, 0, 0.1)"
                        border="1px solid rgba(255, 255, 255, 0.3)"
                        transition="transform 0.3s, box-shadow 0.3s" // Add transition effects
                        _hover={{
                          transform: 'scale(1.03)', // Scale up on hover
                          boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.3)', // Add a shadow on hover
                        }}
                      >
                        <Box position="relative" cursor="pointer">
                        <div style={{ width: '100%', height: '550px' }}>
                        <JessibucaPlayer
                          controls={true}
                          loadingText={'loading'}
                          // /flv/live_ch0_1.flv?verify=a/b4Znt+OFGrYtmHw0T16Q==
                          src={`https://${camera.deviceid}.torqueverse.dev/flv/live_ch0_0.flv?verify=a/b4Znt+OFGrYtmHw0T16Q==`}
                          // src={`https://${camera.deviceid}.torqueverse.dev/flv/live_ch0_0.flv?verify=a/b4Znt+OFGrYtmHw0T16Q==`}
                          decoder="/decoder.js"
                          style={{ width: '100%', height: '100%' }}
                          onError={(error) => {
                            console.error('Video playback error:', error);
                            alert('An error occurred while trying to play the video.');
                          }}
                        />
                      </div>
                        

                          {camera.status === "online" ? (
                            <Badge
                            position="absolute" // Position the badge absolutely within the container
                            top={2} // Top position from the edge of the container
                              right={2} // Right position from the edge of the container
                              fontSize="sm"
                              colorScheme="green" // You can adjust the color scheme of the badge
                            >
                              On 
                            </Badge>
                          ) : (
                            <Badge
                              position="absolute" // Position the badge absolutely within the container
                              top={2} // Top position from the edge of the container
                              right={2} // Right position from the edge of the container
                              fontSize="sm"
                              colorScheme="red" // Set color to red when camera is off
                            >
                              Off
                            </Badge>
                          )}
                        </Box>

                        <HStack justifyContent="space-between" alignItems="center">
                          <Text fontWeight="bold" fontSize="sm" p={1} isTruncated>
                            {camera.cameraname} <span style={{ fontSize: '11px', fontWeight: '500' }}>({camera.deviceid})</span>
                          </Text>

                          {/* Add the Menu component with option dots */}
                          <Menu placement="top">
                            <MenuButton as={IconButton} icon={<FiMoreVertical />} variant="ghost" />
                            <MenuList>
                              <MenuItem onClick={() => handleShareCameraModel(camera.cameraname, camera.cameraid)} icon={<FiShare />}>
                                Share This Camera
                              </MenuItem>
                              {/* <MenuItem onClick={() => ShareThisCamera(camera.cameraname,camera.cameraid)}icon={<FiShare />} >Share This Camera</MenuItem> */}
                              {/* <MenuItem onClick={() => handleCameraSettingsClick(camera.planname,camera.deviceid)} icon={<SettingsIcon />}> Camera Settings</MenuItem> */}
                              <MenuItem onClick={() => handleDeleteCamera(camera.cameraid)} icon={<FiTrash2 />}>
                                Remove Camera
                              </MenuItem>
                              {/* Add more menu items as needed */}
                            </MenuList>
                          </Menu>
                        </HStack>
                      </Box>
                    </GridItem>
                  ))}
                  {/* <CardModel isOpen={isAlertModalOpen} onClose={handleStaticModalClose} videoUrl="" /> */}
                </Grid>
              )}

              {isload && (
                <>
                  <Flex align="center" justify="center" height="100%">
                    <Spinner size="md" thickness="3px" color="blue.500" emptyColor="gray.200" />
                  </Flex>
                </>
              )}
              {cameraList.length === totalitem && (
                <>
                  <Text align="center" justify="center" size="sm" mt={4}>
                    You Have Reached At End Of Your Camera List
                  </Text>
                </>
              )}
            </Box>
   
        </Box>
      </Flex>


    



</>
    
  );
};

export default withAuth(CameraList);