// src/theme.js
import { extendTheme } from '@chakra-ui/react';

const colors = {
  primary: {
    50: '#ffe5e5',
    100: '#fbb8b8',
    200: '#f38a8a',
    300: '#eb5b5b',
    400: '#e32d2d',
    500: '#ca1414', // Custom red from logo
    600: '#a00e0e',
    700: '#750909',
    800: '#4b0303',
    900: '#210000',
  },
  secondary: {
    50: '#e5e5e5',
    100: '#b8b8b8',
    200: '#8a8a8a',
    300: '#5b5b5b',
    400: '#2d2d2d',
    500: '#141414', // Custom dark gray from logo
    600: '#0e0e0e',
    700: '#090909',
    800: '#030303',
    900: '#000000',
  },
};

const theme = extendTheme({ colors });

export default theme;
